import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { setAccount } from '../../../store/accountSlice';
import giftsService from '../../../services/giftsService';
import InfoBox from '../../atoms/InfoBox/InfoBox';
import LinkBox from '../../atoms/LinkBox/LinkBox';

const LogoutPage = () => {

    useEffect(() => {
        giftsService.logout();
    })

    const dispatch = useDispatch();

    dispatch(setAccount(null));

    return (
        <>
            <h2>Logged Out</h2>
            <InfoBox message='You are now logged out.' />
            <LinkBox href="/login" label="Back to home page" />
        </>
    );
}

export default LogoutPage;
import "./LabelledInput.scss";

interface LabelledInputProps {
    name: string;
    label: string;
    type?: string;
    placeholder?: string;
    required: boolean;
    value?: string;
    onChange?: React.ChangeEventHandler<HTMLInputElement>;
}

const LabelledInput = (props: LabelledInputProps) => {
    return (
        <div className="labelled-input">
            <label htmlFor={props.name}>{props.label}</label>
            <input type={props.type ?? 'text'} name={props.name} placeholder={props.placeholder} required={props.required} value={props.value} onChange={props.onChange} />
        </div>
    );
}

export default LabelledInput;
import './MinorButton.scss';

interface MinorButtonProps {
    variant?: 'white' | 'black';
    label: string;
    href: string;
}

const MinorButton = (props: MinorButtonProps) => {
    return (
        <a href={props.href} target="_blank" rel="noreferrer">
            <button className={"minor-button minor-button-" + (props.variant ?? "white")}>{props.label}</button>
        </a>);
}

export default MinorButton;

import { useState } from "react";
import giftsService, { IGiftLinkData } from "../../../services/giftsService";
import ActionButton from "../../atoms/ActionButton/ActionButton";
import GiftLink from "../../atoms/GiftLink/GiftLink";
import LabelledInput from "../../atoms/LabelledInput/LabelledInput";
import './GiftLinkCollection.scss';

interface GiftLinkCollectionProps {
    links: IGiftLinkData[];
    canManage: boolean;
    linkAdded?: (link: IGiftLinkData) => void;
    linkRemoved?: (link: IGiftLinkData) => void;
    linksChanged?: (links: IGiftLinkData[]) => void;
}

const GiftLinkCollection = (props: GiftLinkCollectionProps) => {

    const [addLinkVisible, setAddLinkVisible] = useState(false);

    const [links, setLinks] = useState(props.links);

    const [linkUrl, setLinkUrl] = useState('');

    const [linkTitle, setLinkTitle] = useState('');

    const isValidUrl = (url: string) => {
        let result = null as URL;
        try {
            result = new URL(url);
        } catch (_) {
            return false;
        }
        return result.protocol === "http:" || result.protocol === "https:";
    }

    const handleUrlChanged = async (url: string) => {
        setLinkUrl(url);
        if (!isValidUrl(url)) {
            return;
        }
        await giftsService
            .scrape(url)
            .then(data => {
                setLinkTitle(data.data.pageTitle);
                (document.getElementsByName('linkTitle')[0] as HTMLInputElement).value = data.data.pageTitle;
            })
            .catch(error => {
            });
    }

    const handleAddLinkButtonClick = () => {
        setAddLinkVisible(true);
    }

    const handleCancelAddLinkButtonClick = () => {
        setAddLinkVisible(false);
    }

    const handleAddLink = () => {
        const newLink = { id: -1, pageTitle: linkTitle, pageUrl: linkUrl };
        const newLinks = [...links, newLink];
        setLinks(newLinks);
        setAddLinkVisible(false);
        if (props.linksChanged) {
            props.linksChanged(newLinks);
        }
        if (props.linkAdded) {
            props.linkAdded(newLink);
        }
    }

    const handleRemoveLink = (link: IGiftLinkData) => {
        links.splice(links.indexOf(link), 1);
        setLinks(links);
        if (props.linkRemoved) {
            props.linkRemoved(link);
        }
    }

    return (
        <>
            {links.map(l =>
                <div key={l.id} className="gift-link">
                    <p>
                        <GiftLink url={l.pageUrl} title={l.pageTitle} />
                    </p>
                    {props.canManage && <ActionButton label="Delete Website Link" variant="red" onClick={() => handleRemoveLink(l)} />}
                </div>
            )}

            {addLinkVisible &&
                <div className="gift-link">
                    <h3>Add Gift Link</h3>
                    <LabelledInput name="linkUrl" label="Link URL" placeholder="Enter Website Address (URL)" required={true} onChange={e => handleUrlChanged(e.target.value)} />
                    <LabelledInput name="linkTitle" label="Link Title" placeholder="Enter Link Title" required={true} onChange={e => setLinkTitle(e.target.value)} />
                    <ActionButton label="Add This Link" onClick={() => handleAddLink()} />
                    <ActionButton label="Cancel" variant="red" onClick={() => handleCancelAddLinkButtonClick()} />
                </div>
            }

            {!addLinkVisible && props.canManage && <ActionButton label='Add Link' onClick={() => handleAddLinkButtonClick()} />}
        </>
    );
}

export default GiftLinkCollection;
import LinkBox from '../../atoms/LinkBox/LinkBox';
import ForgotPasswordForm from '../../organisms/ForgotPasswordForm/ForgotPasswordForm';

const ForgotPasswordPage = () => {
    return (
        <>
            <h2>Forgot Password</h2>
            <ForgotPasswordForm />
            <LinkBox href="/login" label="Cancel" />
        </>
    );
}

export default ForgotPasswordPage;
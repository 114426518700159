import { useState } from 'react';
import giftsService from '../../../services/giftsService';
import ActionButton from '../../atoms/ActionButton/ActionButton';
import ErrorAlert from '../../atoms/ErrorAlert/ErrorAlert';
import ExpandedUserFullName from '../../atoms/ExpandedUserFullName/ExpandedUserFullName';
import UserFullName from '../../atoms/UserFullName/UserFullName';
import './FriendRequest.scss';

interface FriendRequestProps {
    id: number;
    userName: string;
    firstName: string;
    lastName: string;
    friendAccepted?: () => void;
}

const FriendRequest = (props: FriendRequestProps) => {

    const [accepted, setAccepted] = useState(false);

    const [rejected, setRejected] = useState(false);

    const [error, setError] = useState('');

    const handleAcceptButtonClick = async () => {
        await giftsService
            .acceptFriendRequest(props.id)
            .then(data => {
                setAccepted(true);
            })
            .catch(error => {
                setError(error.response.data.error);
            });
        if (props.friendAccepted) {
            props.friendAccepted();
        }
    }

    const handleDeclineButtonClick = async () => {
        await giftsService
            .rejectFriendRequest(props.id)
            .then(data => {
                setRejected(true);
            })
            .catch(error => {
                setError(error.response.data.error);
            });
    }

    return (
        <div className="friend-request">
            <ExpandedUserFullName userName={props.userName} firstName={props.firstName} lastName={props.lastName} />
            {error && <ErrorAlert title='Cannot Process Response' message={error} />}
            <div className="friend-request-actions">
                {!accepted && !rejected &&
                    <>
                        <ActionButton label='Accept' onClick={() => handleAcceptButtonClick()} />
                        <ActionButton label='Decline' variant='red' onClick={() => handleDeclineButtonClick()} />
                    </>
                }
                {accepted && <p>You have accepted the friend request from <UserFullName firstName={props.firstName} lastName={props.lastName} /></p>}
                {rejected && <p>You have rejected the friend request from <UserFullName firstName={props.firstName} lastName={props.lastName} /></p>}
            </div>
        </div>
    );
}

export default FriendRequest;
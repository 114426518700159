import { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import useAccount from '../../../hooks/useAccount';
import giftsService, { ICompleteListData, Visibility } from '../../../services/giftsService';
import BackLink from '../../atoms/BackLink/BackLink';
import ErrorAlert from '../../atoms/ErrorAlert/ErrorAlert';
import Spinner from '../../atoms/Spinner/Spinner';
import UserName from '../../atoms/UserName/UserName';
import VisibilityIndicator from '../../atoms/VisibilityIndicator/VisibilityIndicator';
import WideButton from '../../atoms/WideButton/WideButton';
import ConfirmDialog from '../../organisms/ConfirmDialog/ConfirmDialog';
import Gift from '../../organisms/Gift/Gift';
import ListDetailsDialog from '../../organisms/ListDetailsDialog/ListDetailsDialog';

const GiftListPage = () => {

    const { id } = useParams();

    const [loading, setLoading] = useState(true);

    const [list, setList] = useState<ICompleteListData>(null);

    const [listError, setListError] = useState('');

    const [editingList, setEditingList] = useState(false);

    const [confirmDeleteList, setConfirmDeleteList] = useState(false);

    const navigate = useNavigate();

    const account = useAccount();

    const loadList = async () => {
        setList(null);
        await giftsService
            .getList(parseInt(id))
            .then(data => {
                setLoading(false);
                setListError('');
                setList(data.data);
            })
            .catch(error => {
                setLoading(false);
                setListError(error.response.data.error);
            });
    }

    useEffect(() => {
        loadList();
    }, []);

    const handleDeleteListClick = async () => {
        setConfirmDeleteList(true);
    }

    const handleConfirmDeleteList = async () => {
        await giftsService
            .deleteList(parseInt(id))
            .then(data => {
                navigate('/home');
            })
            .catch(error => {
                setListError(error.response.data.error);
            });
    }

    const handleEditListButtonClick = () => {
        setEditingList(true);
    }

    const handleListDetailsChanged = async (name: string, visibility: Visibility, friends: string[]) => {
        setEditingList(false);
        await giftsService
            .editList(parseInt(id), { name: name, visibility: visibility, friends: friends })
            .then(data => {
                loadList();
            })
            .catch(error => {
                setListError(error.response.data.error);
            });
    }

    return (
        <>
            <BackLink />

            {listError && <ErrorAlert title='Cannot Load List' message={listError} />}

            {loading && <Spinner />}

            {list &&
                <>
                    <h1>{list.name}</h1>
                    <VisibilityIndicator visibility={list.visibility} friends={list.friends} variant='black' />

                    {account.id !== list.accountId &&
                        <p>
                            List owned by <UserName userName={list.owner.userName} showAvatar={true} />
                        </p>
                    }

                    {account.id === list.accountId && <WideButton label='Change Name or Visibility' onClick={() => handleEditListButtonClick()} />}

                    {list.items.length === 0 && <p>This list is a list containing no gifts!</p>}
                    {list.items.map(g =>
                        <Gift key={g.id} listId={g.listId} giftId={g.id} title={g.title} notes={g.notes} links={g.links} purchases={g.purchases} />
                    )}

                    {account.id === list.accountId &&
                        <>
                            <WideButton label='Add Gift' navigatesTo={'/lists/' + id + '/add-gift'} />
                            <WideButton label='Delete Gift List' variant='red' onClick={() => handleDeleteListClick()} />
                        </>
                    }
                </>
            }

            {editingList && <ListDetailsDialog open={editingList} name={list.name} visibility={list.visibility} selectedFriends={list.friends} onSaved={(name, visibility, friends) => handleListDetailsChanged(name, visibility, friends)} onCancel={() => setEditingList(false)} />}

            {confirmDeleteList && <ConfirmDialog open={confirmDeleteList} title="Delete List" message={'Do you really want to delete this list?'} onYesClick={() => handleConfirmDeleteList()} onNoClick={() => setConfirmDeleteList(false)} />}
        </>
    );
}

export default GiftListPage;
import { useEffect, useState } from 'react';
import giftsService, { IFriendData, IFriendRequestsData, ISummaryListData } from '../../../services/giftsService';
import ErrorAlert from '../../atoms/ErrorAlert/ErrorAlert';
import FriendRequest from '../../organisms/FriendRequest/FriendRequest';
import UserFullName from '../../atoms/UserFullName/UserFullName';
import WideButton from '../../atoms/WideButton/WideButton';
import FriendSummary from '../../organisms/FriendSummary/FriendSummary';
import GiftListSummary from '../../organisms/GiftListSummary/GiftListSummary';
import ActionButton from '../../atoms/ActionButton/ActionButton';
import Spinner from '../../atoms/Spinner/Spinner';
import useAccount from '../../../hooks/useAccount';
import ToggleSwitch from '../../atoms/ToggleSwitch/ToggleSwitch';
import ConfirmDialog from '../../organisms/ConfirmDialog/ConfirmDialog';
import './HomePage.scss';

const HomePage = () => {

    const [loadingLists, setLoadingLists] = useState(true);

    const [lists, setLists] = useState<ISummaryListData[]>(null);

    const [listsError, setListsError] = useState('');

    const [loadingFriends, setLoadingFriends] = useState(true);

    const [friends, setFriends] = useState<IFriendData[]>(null);

    const [friendsError, setFriendsError] = useState('');

    const [loadingFriendRequests, setLoadingFriendRequests] = useState(true);

    const [friendRequests, setFriendRequests] = useState<IFriendRequestsData>(null);

    const [friendRequestsError, setFriendRequestsError] = useState('');

    const [showPrivateLists, setShowPrivateLists] = useState((localStorage.getItem('gifts.showPrivateLists') ?? 'true') === 'true');

    const [confirmCancelFriendId, setConfirmCancelFriendId] = useState(-1);

    const loadMyLists = async () => {
        setLoadingLists(true);
        setLists(null);
        await giftsService
            .getLists()
            .then(data => {
                setLoadingLists(false);
                setListsError('');
                setLists(data.data);
            })
            .catch(error => {
                setLoadingLists(false);
                setListsError(error.response.data.error);
            });
    }

    const loadFriends = async () => {
        setLoadingFriends(true);
        setFriends(null);
        await giftsService
            .getFriends()
            .then(data => {
                setLoadingFriends(false);
                setFriendsError('');
                setFriends(data.data);
            })
            .catch(error => {
                setLoadingFriends(false);
                setFriendsError(error.response.data.error);
            });
    }

    const loadPendingFriendRequests = async () => {
        setLoadingFriendRequests(true);
        setFriendRequests(null);
        await giftsService
            .getFriendRequests()
            .then(data => {
                setLoadingFriendRequests(false);
                setFriendRequestsError('');
                setFriendRequests(data.data);
            })
            .catch(error => {
                setLoadingFriendRequests(false);
                setFriendRequestsError(error.response.data.error);
            });
    }

    useEffect(() => {
        loadMyLists();
        loadFriends();
        loadPendingFriendRequests();
    }, []);

    const handleCancelFriendRequest = async (id: number) => {
        setConfirmCancelFriendId(id);
    }

    const handleConfirmCancelFriendRequest = async (id: number) => {
        await giftsService
            .cancelFriendRequest(id)
            .then(data => {
                setConfirmCancelFriendId(-1);
                loadPendingFriendRequests();
            })
            .catch(error => {
                setFriendRequestsError(error.response.data.error);
            });
    }

    const handleUnfriended = () => {
        loadFriends();
    }

    const handleFriendAccepted = () => {
        loadFriends();
    }

    const account = useAccount();

    const handleChangePrivateVisibility = (checked: boolean) => {
        setShowPrivateLists(checked);
        localStorage.setItem('gifts.showPrivateLists', checked.toString());
    }

    return (
        <>

            <h1>My Gift Lists</h1>

            {loadingLists && <Spinner />}

            {lists?.length === 0 && <p>You have not created any gift lists!</p>}

            {lists && <ToggleSwitch label='Include private lists' checked={showPrivateLists} onChange={handleChangePrivateVisibility} />}

            {lists?.map(l =>
                (showPrivateLists || l.visibility !== 'private') && <GiftListSummary key={l.id} id={l.id} name={l.name} giftCount={l.giftCount} visibility={l.visibility} />
            )}

            {listsError && <ErrorAlert title='Cannot Get Your Gift Lists' message={listsError} />}

            <WideButton label='Create Gift List' navigatesTo='/new-list' />

            <h1>My Friends</h1>

            {loadingFriends && <Spinner />}

            {friends?.length === 0 && <p>You do not have any friends!</p>}

            {friends?.map(f =>
                <FriendSummary key={f.id} id={f.id} userName={f.userName} firstName={f.firstName} lastName={f.lastName} lists={f.lists} onUnfriend={() => handleUnfriended()} />
            )}

            {friendsError && <ErrorAlert title='Cannot Get Your Friends' message={friendsError} />}

            {(friendRequests?.forMe?.length > 0 || friendRequests?.forOthers?.length > 0) && <h1>Friend Requests</h1>}

            {friendRequests?.forMe?.map(f =>
                <FriendRequest key={f.id} id={f.id} userName={f.userName} firstName={f.firstName} lastName={f.lastName} friendAccepted={() => handleFriendAccepted()} />
            )}

            {friendRequests?.forOthers?.map(f =>
                <div className="my-friend-request">
                    <p key={f.id}>
                        Awaiting friend request response from: <UserFullName firstName={f.firstName} lastName={f.lastName} />
                    </p>
                    <ActionButton label='Cancel' variant='red' onClick={() => handleCancelFriendRequest(f.id)} />
                </div>
            )}

            {friendRequestsError && <ErrorAlert title='Cannot Get Your Friend Requests' message={friendRequestsError} />}

            {confirmCancelFriendId !== -1 && <ConfirmDialog open={confirmCancelFriendId !== -1} title="Cancel Friend Request" message={'Do you really want to cancel the friend request?'} onYesClick={() => handleConfirmCancelFriendRequest(confirmCancelFriendId)} onNoClick={() => setConfirmCancelFriendId(-1)} />}

            <WideButton label='Add Friend' navigatesTo='/friend-search' />

        </>
    );
}

export default HomePage;
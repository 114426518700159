import { Link } from 'react-router-dom';
import { Visibility } from '../../../services/giftsService';
import VisibilityIndicator from '../../atoms/VisibilityIndicator/VisibilityIndicator';
import './GiftListSummary.scss';

interface GiftListSummaryProps {
    id: number;
    name: string;
    giftCount: number;
    visibility: Visibility;
}

const GiftListSummary = (props: GiftListSummaryProps) => {
    let className = 'list-summary-container list-summary-container-' + props.visibility;
    return (
        <Link to={"/lists/" + props.id}>
            <div className={className}>
                <p className="list-summary-header">
                    <span className="list-summary-name">
                        {props.name}
                    </span>
                    <span className="list-summary-count">({props.giftCount} gift{props.giftCount === 1 ? '' : 's'})</span>
                </p>
                {props.visibility && <p className="list-summary-visibility-indicator">
                    <VisibilityIndicator visibility={props.visibility} />
                </p>}
                <div className="list-summary-end"></div>
            </div>
        </Link>
    );
}

export default GiftListSummary;
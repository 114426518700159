import "./LabelledComboBox.scss";

interface LabelledComboBoxProps {
    name: string;
    label: string;
    options: Array<{ label: string; value: string }>;
    value?: string;
    onChange?: React.ChangeEventHandler<HTMLSelectElement>;
}

const LabelledComboBox = (props: LabelledComboBoxProps) => {
    return (
        <div className="labelled-combo-box">
            <label htmlFor={props.name}>{props.label}</label>
            <select name={props.name} onChange={props.onChange} value={props.value}>
                {props.options.map(o =>
                    <option key={o.value} value={o.value}>{o.label}</option>
                )}
            </select>
        </div>
    );
}

export default LabelledComboBox;
import LinkBox from '../../atoms/LinkBox/LinkBox';
import RegisterForm from '../../organisms/RegisterForm/RegisterForm';

const RegisterPage = () => {
    return (
        <>
            <h2>Register Gifts Account</h2>
            <RegisterForm />
            <LinkBox href="/login" label="Cancel" />
        </>
    );
}

export default RegisterPage;
import { useState } from 'react';
import LabelledInput from '../../atoms/LabelledInput/LabelledInput';
import LabelledTextArea from '../../atoms/LabelledTextArea/LabelledTextArea';
import WideButton from '../../atoms/WideButton/WideButton';
import './GiftDetailsDialog.scss';

interface GiftDetailsDialogProps {
    title: string;
    notes: string;
    open: boolean;
    onSaved: (title: string, notes: string) => void;
    onCancel:()=>void;
}

const GiftDetailsDialog = (props: GiftDetailsDialogProps) => {

    const [open, setOpen] = useState(props.open);

    const [title, setTitle] = useState(props.title);

    const [notes, setNotes] = useState(props.notes);

    const handleSaveButtonClick = () => {
        setOpen(false);
        props.onSaved(title, notes);
    }

    const handleCancelButtonClick = () => {
        setOpen(false);
        props.onCancel();
    }

    return (
        <dialog className="gift-details-dialog" open={open}>

            <LabelledInput name="title" label="Title" placeholder="Enter Title" required={true} value={title} onChange={e => setTitle(e.target.value)} />

            <LabelledTextArea name="notes" label="Notes" required={true} value={notes} onChange={e => setNotes(e.target.value)} />

            <WideButton label='Save' onClick={() => handleSaveButtonClick()} />

            <WideButton label='Cancel' variant='red' onClick={() => handleCancelButtonClick()} />

        </dialog>
    );
}

export default GiftDetailsDialog;
import { Outlet } from "react-router-dom";
import Header from "../../organisms/Header/Header";
import "./Layout.scss"

const Layout = () => {
    return (
        <>
            <Header />
            <div className="container">
                <Outlet />
            </div>
        </>
    );
};

export default Layout;

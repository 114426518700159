import { useEffect, useState } from 'react';
import giftsService, { IFriendData, Visibility } from '../../../services/giftsService';
import LabelledInput from '../../atoms/LabelledInput/LabelledInput';
import WideButton from '../../atoms/WideButton/WideButton';
import ListVisibilitySelector from '../ListVisibilitySelector/ListVisibilitySelector';
import './ListDetailsDialog.scss';

interface ListDetailsDialogProps {
    name: string;
    visibility: Visibility;
    open: boolean;
    selectedFriends: string[];
    onSaved: (name: string, visibility: Visibility, friends: string[]) => void;
    onCancel:()=>void;
}

const ListDetailsDialog = (props: ListDetailsDialogProps) => {

    const [open, setOpen] = useState(props.open);

    const [name, setName] = useState(props.name);

    const [visibility, setVisibility] = useState(props.visibility);

    const [friends, setFriends] = useState<IFriendData[]>(null);

    const [selectedFriends, setSelectedFriends] = useState<string[]>(props.selectedFriends);

    const loadFriends = async () => {
        setFriends(null);
        await giftsService
            .getFriends()
            .then(data => {
                setFriends(data.data);
            })
            .catch(error => {
            });
    }

    useEffect(() => {
        loadFriends();
    }, []);

    const handleVisibilityChanged = (visibility: Visibility, friends: string[]) => {
        setVisibility(visibility);
        setSelectedFriends(friends);
    }

    const handleSaveButtonClick = () => {
        setOpen(false);
        props.onSaved(name, visibility, selectedFriends);
    }

    const handleCancelButtonClick = () => {
        setOpen(false);
        props.onCancel();
    }

    return (
        <>
            <dialog className="list-details-dialog" open={open}>

                <LabelledInput name="listName" label="List Name" placeholder="Enter list name" required={true} value={name} onChange={e => setName(e.target.value)} />

                <ListVisibilitySelector visibility={visibility} friends={friends} selectedFriends={selectedFriends} onChange={(visibility, friends) => handleVisibilityChanged(visibility, friends)} />

                <WideButton label='Save' onClick={() => handleSaveButtonClick()} />
                
                <WideButton label='Cancel' variant='red' onClick={() => handleCancelButtonClick()} />
            </dialog>
        </>
    );
}

export default ListDetailsDialog;
import { useEffect, useState } from 'react';
import giftsService, { IListData } from '../../../services/giftsService';
import ErrorAlert from '../../atoms/ErrorAlert/ErrorAlert';
import LabelledComboBox from '../../atoms/LabelledComboBox/LabelledComboBox';
import Spinner from '../../atoms/Spinner/Spinner';
import WideButton from '../../atoms/WideButton/WideButton';
import './CopyGiftDialog.scss';

interface CopyGiftDialogProps {
    listId: number;
    giftId: number;
    open: boolean;
    onCopied: () => void;
    onCancel: () => void;
}

const CopyGiftDialog = (props: CopyGiftDialogProps) => {

    const [open, setOpen] = useState(props.open);

    const [loadingLists, setLoadingLists] = useState(true);

    const [error, setError] = useState('');

    const [availableLists, setAvailableLists] = useState<IListData[]>([]);

    const [list, setList] = useState(-1);

    const handleCopyButtonClick = async () => {
        await giftsService
            .copyGift(props.listId, props.giftId, list)
            .then(data => {
                setOpen(false);
                props.onCopied();
            })
            .catch(error => {
                setError(error.response.data.error);
            });
    }

    const handleCancelButtonClick = () => {
        setOpen(false);
        props.onCancel();
    }

    const loadLists = async () => {
        setLoadingLists(true);
        setAvailableLists([]);
        await giftsService
            .getLists()
            .then(data => {
                setLoadingLists(false);
                setError('');
                setAvailableLists(data.data);
            })
            .catch(error => {
                setLoadingLists(false);
                setError(error.response.data.error);
            });
    }

    useEffect(() => {
        loadLists();
    }, []);

    return (
        <dialog className="copy-gift-dialog" open={open}>

            <h2>Copy Gift</h2>

            {loadingLists && <Spinner />}

            {!loadingLists &&
                <>
                    <LabelledComboBox name="list" label="List" onChange={e => setList(parseInt(e.target.value))}
                        options={availableLists.map(l => { return { value: l.id.toString(), label: l.name } })} />

                    {error && <ErrorAlert title='Cannot Load Lists' message={error} />}

                    <WideButton label='Copy' onClick={() => handleCopyButtonClick()} />

                    <WideButton label='Cancel' variant='red' onClick={() => handleCancelButtonClick()} />
                </>
            }

        </dialog>
    );
}

export default CopyGiftDialog;
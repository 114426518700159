import "./LabelledReadOnlyField.scss";

interface LabelledReadOnlyFieldProps {
    label: string;
    value: string;
}

const LabelledInput = (props: LabelledReadOnlyFieldProps) => {
    return (
        <div className="labelled-read-only-field">
            <label className="labelled-read-only-field-name">{props.label}</label>
            <p className="labelled-read-only-field-value">{props.value}</p>
        </div>
    );
}

export default LabelledInput;
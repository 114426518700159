import "./LabelledTextArea.scss";

interface LabelledTextAreaProps {
    name: string;
    label: string;
    placeholder?: string;
    required: boolean;
    value?: string;
    onChange?: React.ChangeEventHandler<HTMLTextAreaElement>;
}

const LabelledInput = (props: LabelledTextAreaProps) => {
    return (
        <div className="labelled-textarea">
            <label htmlFor={props.name}>{props.label}</label>
            <textarea name={props.name} placeholder={props.placeholder} required={props.required} value={props.value} onChange={props.onChange} />
        </div>
    );
}

export default LabelledInput;
import config from "../config.json";
import axios, { AxiosInstance, AxiosResponse } from "axios";

export type Visibility = 'friends' | 'specific' | 'private';

export interface IAccountRegistration {
    userName: string;
    emailAddress: string;
    firstName: string;
    lastName: string;
    password: string;
}

export interface IResetPasswordRequest {
    userName: string;
    emailAddress: string;
}

export interface IChangePasswordRequest {
    userName: string;
    newPassword: string;
    changeCode: number;
}

export interface ILoginRequest {
    userName: string;
    password: string;
}

export interface ICreateFriendRequestData {
    userName: string;
}

export interface IAccountData {
    id: number;
    userName: string;
    firstName: string;
    lastName: string;
}

export interface ISessionData extends IAccountData {
    token: string;
}

export interface IFriendRequestsData {
    forMe: IAccountData[];
    forOthers: IAccountData[];
}

export interface IFriendData {
    id: number;
    userName: string;
    firstName: string;
    lastName: string;
    email: string;
    lists: Array<{ id: number; accountId: number; name: string; visibility: Visibility; giftCount: number }>;
}

export interface INewListData {
    name: string;
    visibility: Visibility;
    friends: string[];
}

export interface IListData {
    id: number;
    accountId: number;
    name: string;
    visibility: Visibility;
}

export interface IListDataChanges {
    name: string;
    visibility: Visibility;
    friends: string[];
}

export interface ISummaryListData extends IListData {
    giftCount: number;
}

export interface IGiftPurchaseData {
    listId: number;
    id: number;
    title: string;
    notes: string;
    userId: number;
    userName: string;
    firstName: string;
    lastName: string
}

export interface ICompleteListData extends IListData {
    owner: IAccountData;
    items: Array<{
        id: number;
        listId: number;
        title: string;
        notes: string;
        links: Array<{ id: number; itemId: number; pageUrl: string; shortUrl: string; pageTitle: string; imageUrl: string }>;
        purchases: IGiftPurchaseData[];
    }>;
    friends: string[];
}

export interface INewGiftData {
    title: string;
    notes: string;
    links: IGiftLinkData[];
}

export interface IGiftLinkData {
    id: number;
    pageUrl: string;
    pageTitle: string;
}

export interface IGiftData {
    id: number;
    listId: number,
    listName: string;
    accountId: number;
    requestor: IAccountData;
    title: string;
    notes: string;
    links: IGiftLinkData[];
    purchases: Array<{ userName: string; firstName: string; lastName: string }>;
}

export interface IGiftDataChanges {
    title: string;
    notes: string;
}

export interface IScrapedPageData {
    url: string;
    pageTitle: string;
}

export interface IIdResponse {
    id: number;
}

export interface IError {
    error: string;
}

class GiftsService {

    private axios: AxiosInstance;

    public readonly baseUrl: string;

    private bearerToken: string = null;

    public loggedIn: boolean = false;

    constructor() {
        this.baseUrl = config.giftsApiBaseUrl;
        this.axios = axios.create({ baseURL: config.giftsApiBaseUrl });
        this.bearerToken = sessionStorage.getItem('gifts.bearerToken');

        this.axios.interceptors.response.use(response => {
            return response;
        }, (error) => {
            const status = error.response?.status || 500;
            if (status === 401) {
                let path = window.location.pathname.replace(process.env.PUBLIC_URL, '');
                window.location.href = process.env.PUBLIC_URL + '/login?returnUrl=' + path;
            } else {
                return Promise.reject(error);
            }
        });
    }

    async registerAccount(data: IAccountRegistration) {
        return await this.axios.post('accounts', data);
    }

    async forgotPassword(data: IResetPasswordRequest) {
        return await this.axios.post('forgot-password', data);
    }

    async changePassword(data: IChangePasswordRequest) {
        return await this.axios.post('reset-password', data);
    }

    async login(data: ILoginRequest): Promise<AxiosResponse<ISessionData>> {
        this.loggedIn = true;

        const auth = { username: data.userName, password: data.password };
        const response = await this.axios.post('login', null, { auth: auth });

        if (response.status === 200) {
            localStorage.setItem('gifts.userId', response.data.id.toString());
            localStorage.setItem('gifts.userName', response.data.userName);
            localStorage.setItem('gifts.firstName', response.data.firstName);
            localStorage.setItem('gifts.lastName', response.data.lastName);
            sessionStorage.setItem('gifts.bearerToken', response.data.token);
            this.bearerToken = response.data.token;
        }

        return response;
    }

    async logout() {
        this.bearerToken = null;
        this.loggedIn = false;
        localStorage.removeItem('gifts.userId');
        localStorage.removeItem('gifts.userName');
        localStorage.removeItem('gifts.firstName');
        localStorage.removeItem('gifts.lastName');
        sessionStorage.removeItem('gifts.bearerToken');
    }

    async searchForAccounts(term: string): Promise<AxiosResponse<IAccountData[]>> {
        return await this.axios.post('search-accounts', { term: term }, this.getRequestConfig());
    }

    async createFriendRequest(data: ICreateFriendRequestData) {
        return await this.axios.post('friend-requests', data, this.getRequestConfig());
    }

    async getFriendRequests(): Promise<AxiosResponse<IFriendRequestsData>> {
        return await this.axios.get('friend-requests', this.getRequestConfig());
    }

    async cancelFriendRequest(id: number) {
        return await this.axios.post('friend-requests/' + encodeURIComponent(id) + '/cancel', null, this.getRequestConfig());
    }

    async acceptFriendRequest(id: number) {
        return await this.axios.post('friend-requests/' + encodeURIComponent(id) + '/accept', null, this.getRequestConfig());
    }

    async rejectFriendRequest(id: number) {
        return await this.axios.post('friend-requests/' + encodeURIComponent(id) + '/reject', null, this.getRequestConfig());
    }

    async getFriends(): Promise<AxiosResponse<IFriendData[]>> {
        return await this.axios.get('friends', this.getRequestConfig());
    }

    async removeFriend(id: number) {
        return await this.axios.delete('friends/' + encodeURIComponent(id), this.getRequestConfig());
    }

    async createList(data: INewListData): Promise<AxiosResponse<IIdResponse>> {
        return await this.axios.post('lists', data, this.getRequestConfig());
    }

    async getLists(): Promise<AxiosResponse<ISummaryListData[]>> {
        return await this.axios.get('lists', this.getRequestConfig());
    }

    async getList(id: number): Promise<AxiosResponse<ICompleteListData>> {
        return await this.axios.get('lists/' + encodeURIComponent(id), this.getRequestConfig());
    }

    async editList(listId: number, data: IListDataChanges) {
        return await this.axios.put('lists/' + encodeURIComponent(listId), data, this.getRequestConfig());
    }

    async deleteList(id: number): Promise<AxiosResponse<ICompleteListData>> {
        return await this.axios.delete('lists/' + encodeURIComponent(id), this.getRequestConfig());
    }

    async createGift(listId: number, data: INewGiftData) {
        return await this.axios.post('lists/' + encodeURIComponent(listId) + '/gifts', data, this.getRequestConfig());
    }

    async getGift(listId: number, giftId: number): Promise<AxiosResponse<IGiftData>> {
        return await this.axios.get('lists/' + encodeURIComponent(listId) + '/gifts/' + encodeURIComponent(giftId), this.getRequestConfig());
    }

    async editGift(listId: number, giftId: number, data: IGiftDataChanges) {
        return await this.axios.put('lists/' + encodeURIComponent(listId) + '/gifts/' + encodeURIComponent(giftId), data, this.getRequestConfig());
    }

    async deleteGift(listId: number, giftId: number): Promise<AxiosResponse<ICompleteListData>> {
        return await this.axios.delete('lists/' + encodeURIComponent(listId) + '/gifts/' + encodeURIComponent(giftId), this.getRequestConfig());
    }

    async createGiftLink(listId: number, giftId: number, data: IGiftLinkData): Promise<AxiosResponse<IIdResponse>> {
        return await this.axios.post('lists/' + encodeURIComponent(listId) + '/gifts/' + encodeURIComponent(giftId) + '/links', data, this.getRequestConfig());
    }

    async deleteGiftLink(listId: number, giftId: number, linkId: number) {
        return await this.axios.delete('lists/' + encodeURIComponent(listId) + '/gifts/' + encodeURIComponent(giftId) + '/links/' + encodeURIComponent(linkId), this.getRequestConfig());
    }

    async copyGift(listId: number, giftId: number, newListId: number) {
        return await this.axios.post('lists/' + encodeURIComponent(listId) + '/gifts/' + encodeURIComponent(giftId) + '/copy', { listId: newListId }, this.getRequestConfig());
    }

    async markAsPurchased(listId: number, giftId: number) {
        return await this.axios.put('lists/' + encodeURIComponent(listId) + '/gifts/' + encodeURIComponent(giftId) + '/purchase', null, this.getRequestConfig());
    }

    async markAsUnpurchased(listId: number, giftId: number) {
        return await this.axios.delete('lists/' + encodeURIComponent(listId) + '/gifts/' + encodeURIComponent(giftId) + '/purchase', this.getRequestConfig());
    }

    async scrape(url: string): Promise<AxiosResponse<IScrapedPageData>> {
        return await this.axios.post('scrape-page', { url: url }, this.getRequestConfig());
    }

    private getRequestConfig() {
        return {
            headers: {
                'Authorization': `Bearer ${this.bearerToken}`
            }
        }
    }

}

const giftsService = new GiftsService();

export default giftsService;

import { Link } from "react-router-dom";
import useTheme from "../../../hooks/useTheme";
import MainLogo from "../../atoms/MainLogo/MainLogo";
import UserName from "../../atoms/UserName/UserName";
import './Header.scss';
import { useSelector } from 'react-redux';

const Header = () => {

    const theme = useTheme();

    const account = useSelector((state: any) => state.account.value);

    return (
        <div className="top-bar">
            {theme && <img className="top-bar-banner" src={process.env.PUBLIC_URL + "/banner-" + theme + ".jpg"} alt={theme} />}
            <div className={"top-bar-contents" + (theme ? ' top-bar-contents-invert' : '')}>
                <MainLogo />

                {account &&
                    <p className="top-bar-contents-right">
                        <UserName userName={account.userName} showAvatar={true} />
                        <br />
                        <Link to="/logout">Log out</Link>
                    </p>
                }
            </div>
        </div>
    );
}

export default Header;
import Avatar from '../Avatar/Avatar';
import './UserName.scss';

interface UserNameProps {
    userName: string;
    showAvatar?: boolean;
}

const UserName = (props: UserNameProps) => {
    return (
        <div className="user-name">
            {props.showAvatar && <Avatar />}
            <span className='user-name-text'>{props.userName}</span>
        </div>
    );
}

export default UserName;
import { Visibility } from "../../../services/giftsService";
import UserName from "../UserName/UserName";
import VisibilityIcon from "../VisibilityIcon/VisibilityIcon";
import './VisibilityIndicator.scss';

interface VisibilityIndicatorProps {
    visibility: Visibility;
    variant?: 'white' | 'black';
    friends?: string[];
}

const VisibilityIndicator = (props: VisibilityIndicatorProps) => {
    return (
        <span className="visibility-indicator">
            <span>
                <VisibilityIcon variant={props.variant} />
            </span>
            <span className="visibility-indicator-text">
                {props.visibility}
            </span>
            {props.friends && props.friends.map(f =>
                <div key={f} className="visibility-indicator-friend">
                    <UserName userName={f} showAvatar={true} />
                </div>
            )}
        </span>
    );
}

export default VisibilityIndicator;
import './App.scss';
import { BrowserRouter, Routes, Route } from "react-router-dom";
import LoginPage from './components/pages/LoginPage/LoginPage';
import RegisterPage from './components/pages/RegisterPage/RegisterPage';
import Layout from './components/templates/Layout/Layout';
import ForgotPasswordPage from './components/pages/ForgotPasswordPage/ForgotPasswordPage';
import HomePage from './components/pages/HomePage/HomePage';
import FriendSearchPage from './components/pages/FriendSearchPage/FriendSearchPage';
import CreateGiftListPage from './components/pages/CreateGiftListPage/CreateGiftListPage';
import CreateGiftPage from './components/pages/CreateGiftPage/CreateGiftPage';
import GiftPage from './components/pages/GiftPage/GiftPage';
import GiftListPage from './components/pages/GiftListPage/GiftListPage';
import LogoutPage from './components/pages/LogoutPage/LogoutPage';
import ResetPasswordPage from './components/pages/ResetPasswordPage/ResetPasswordPage';

const App = () => {

  return (
    <BrowserRouter basename="/gifts">
      <Routes>
        <Route path="/" element={<Layout />}>
          <Route path="/" element={<LoginPage />} />
          <Route path="/login" element={<LoginPage />} />
          <Route path="/logout" element={<LogoutPage />} />
          <Route path="/register" element={<RegisterPage />} />
          <Route path="/forgot-password" element={<ForgotPasswordPage />} />
          <Route path="/reset-password" element={<ResetPasswordPage />} />
          <Route path="/home" element={<HomePage />} />
          <Route path="/myLists" element={<HomePage />} />
          <Route path="/friend-search" element={<FriendSearchPage />} />
          <Route path="/new-list" element={<CreateGiftListPage />} />
          <Route path="/lists/:id" element={<GiftListPage />} />
          <Route path="/lists/:listId/add-gift" element={<CreateGiftPage />} />
          <Route path="/lists/:listId/gifts/:giftId" element={<GiftPage />} />
        </Route>
      </Routes>
    </BrowserRouter>
  );
}

export default App;

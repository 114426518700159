import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import giftsService from '../../../services/giftsService';
import ErrorAlert from '../../atoms/ErrorAlert/ErrorAlert';
import LabelledInput from '../../atoms/LabelledInput/LabelledInput';
import WideButton from '../../atoms/WideButton/WideButton';

const RegisterForm = () => {

    const [userName, setUserName] = useState('');
    const [password, setPassword] = useState('');
    const [firstName, setFirstName] = useState('');
    const [lastName, setLastName] = useState('');
    const [emailAddress, setEmailAddress] = useState('');

    const [error, setError] = useState('');

    let navigate = useNavigate();
    
    const handleRegisterButtonClick = async () => {
        await giftsService
            .registerAccount({
                userName: userName,
                password: password,
                firstName: firstName,
                lastName: lastName,
                emailAddress: emailAddress
            })
            .then(data => {
                navigate('/');
            })
            .catch(error => {
                setError(error.response.data.error);
            });
    }

    return (
        <>
            <form method="post">

                <LabelledInput name="userName" label="Username" placeholder="Enter Username" required={true} onChange={e => setUserName(e.target.value)} />
                <LabelledInput name="firstName" label="First Name" placeholder="Enter First Name" required={true} onChange={e => setFirstName(e.target.value)} />
                <LabelledInput name="lastName" label="Last Name" placeholder="Enter Last Name" required={true} onChange={e => setLastName(e.target.value)} />
                <LabelledInput name="password" type="password" label="Password" placeholder="Enter Password" required={true} onChange={e => setPassword(e.target.value)} />
                <LabelledInput name="emailAddress" label="Email Address" placeholder="Enter Email Address" required={true} onChange={e => setEmailAddress(e.target.value)} />

                <WideButton label='Create Account' onClick={() => handleRegisterButtonClick()} />

                {error !== '' && <ErrorAlert title='Error Registering Account' message={error} />}

            </form>
        </>
    );

}

export default RegisterForm;
import { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import giftsService, { ICompleteListData, IGiftLinkData } from '../../../services/giftsService';
import BackLink from '../../atoms/BackLink/BackLink';
import ErrorAlert from '../../atoms/ErrorAlert/ErrorAlert';
import LabelledInput from '../../atoms/LabelledInput/LabelledInput';
import LabelledReadOnlyField from '../../atoms/LabelledReadOnlyField/LabelledReadOnlyField';
import LabelledTextArea from '../../atoms/LabelledTextArea/LabelledTextArea';
import LinkBox from '../../atoms/LinkBox/LinkBox';
import Spinner from '../../atoms/Spinner/Spinner';
import WideButton from '../../atoms/WideButton/WideButton';
import GiftLinkCollection from '../../organisms/GiftLinkCollection/GiftLinkCollection';

const CreateGiftPage = () => {

    const { listId } = useParams();

    const [loading, setLoading] = useState(true);

    const [list, setList] = useState<ICompleteListData>(null);

    const [title, setTitle] = useState('');

    const [notes, setNotes] = useState('');

    const [error, setError] = useState('');

    const [links, setLinks] = useState([]);

    let navigate = useNavigate();

    const loadList = async () => {
        await giftsService
            .getList(parseInt(listId))
            .then(data => {
                setLoading(false);
                setList(data.data);
            })
            .catch(error => {
                setLoading(false);
            });
    }

    useEffect(() => {
        loadList();
    }, []);

    const handleCreateGiftButtonClick = async () => {
        await giftsService
            .createGift(parseInt(listId), {
                title: title,
                notes: notes,
                links: links
            })
            .then(data => {
                navigate('/lists/' + encodeURIComponent(listId));
            })
            .catch(error => {
                setError(error.response.data.error);
            });
    }

    const handleLinksChanged = (links: IGiftLinkData[]) => {
        setLinks(links);
    }

    return (
        <>
            <BackLink label={list ? list.name : 'Back to List'} href={'/lists/' + parseInt(listId)} />

            <h2>Create Gift</h2>

            <form method="post">

                {loading
                    ? <Spinner />
                    : <LabelledReadOnlyField label='List' value={list.name} />
                }

                <LabelledInput name="title" label="Title" placeholder="Enter Title" required={true} onChange={e => setTitle(e.target.value)} />

                <LabelledTextArea name="notes" label="Notes" required={true} onChange={e => setNotes(e.target.value)} />

                <h2>Website Links</h2>

                <GiftLinkCollection canManage={true} links={[]} linksChanged={links => handleLinksChanged(links)} />

                {error !== '' && <ErrorAlert title='Error Creating Gift' message={error} />}

                <WideButton label='Create' onClick={() => handleCreateGiftButtonClick()} />

            </form>

            <LinkBox href={"/lists/" + encodeURIComponent(listId)} label="Cancel" />
        </>
    );
}

export default CreateGiftPage;
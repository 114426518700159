import { Link } from 'react-router-dom';
import './MainLogo.scss';

const MainLogo = () => {
    return (
        <Link to="/home" className='main-logo-link'>
            <img id="main-logo" src={process.env.PUBLIC_URL + "/mainLogo.png"} alt="Gift Mouse" />
            </Link>
    );
}

export default MainLogo;
import './GiftLink.scss';

interface GiftLinkProps {
    title: string;
    url: string;
}

const GiftLink = (props: GiftLinkProps) => {
    return (
        <>
            <span className="gift-link-title"><a href={props.url} target="_blank" rel="noreferrer">{props.title}</a></span><br />
            <span className="gift-link-url"><a href={props.url} target="_blank" rel="noreferrer">{props.url}</a></span>
        </>
    );
}

export default GiftLink;
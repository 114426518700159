import { Link } from 'react-router-dom';
import { IGiftLinkData, IGiftPurchaseData } from '../../../services/giftsService';
import StringParagraphs from '../../atoms/StringParagraphs/StringParagraphs';
import GiftLink from '../../atoms/GiftLink/GiftLink';
import './Gift.scss';
import InfoBox from '../../atoms/InfoBox/InfoBox';

interface GiftProps {
    listId: number;
    giftId: number;
    title: string;
    notes: string;
    links: IGiftLinkData[];
    purchases: IGiftPurchaseData[];
}

const Gift = (props: GiftProps) => {

    return (
        <div className="gift">

            <Link to={'/lists/' + encodeURIComponent(props.listId) + '/gifts/' + encodeURIComponent(props.giftId)}>
                <h2 className="gift-title">{props.title}</h2>
            </Link>

            {props.notes && props.notes !== '' && <StringParagraphs text={props.notes} />}

            {props.links &&
                <table className="gift-links-table">
                    <tbody>
                        {props.links.map(l =>
                            <tr key={l.id}>
                                <td>
                                    <p className="gift-link-icon"><a href={l.pageUrl} target="_blank" rel="noreferrer">🔗</a></p>
                                </td>
                                <td>
                                    <GiftLink url={l.pageUrl} title={l.pageTitle} />
                                </td>
                            </tr>
                        )}
                    </tbody>
                </table>
            }

            {props.purchases?.map(p =>
                <InfoBox key={p.id} message={'Purchased by ' + p.userName} />
            )}
        </div>
    );
}

export default Gift;
const StringParagraphs = (props: { text: string }) => {

    return (
        <>
            {props.text.split("\n").map((item, idx) => {
                return (
                    <p key={idx}>{item}</p>
                )
            })}
        </>
    );
}

export default StringParagraphs;
import InfoIcon from '../InfoIcon/InfoIcon';
import './InfoBox.scss';

interface InfoBoxProps {
    message: string;
}

const InfoBox = (props: InfoBoxProps) => {
    return (
        <p className="info">
            <InfoIcon />
            {props.message}
        </p>
    );
}

export default InfoBox;
import LinkBox from '../../atoms/LinkBox/LinkBox';
import ResetPasswordForm from '../../organisms/ResetPasswordForm/ResetPasswordForm';

const ResetPasswordPage = () => {
    return (
        <>
            <h2>Reset Password</h2>
            <ResetPasswordForm />
            <LinkBox href="/login" label="Cancel" />
        </>
    );
}

export default ResetPasswordPage;
import { useState } from 'react';
import giftsService, { Visibility } from '../../../services/giftsService';
import ErrorAlert from '../../atoms/ErrorAlert/ErrorAlert';
import ExpandedUserFullName from '../../atoms/ExpandedUserFullName/ExpandedUserFullName';
import GiftListSummary from '../GiftListSummary/GiftListSummary';
import './FriendSummary.scss';
import NullNotice from '../../atoms/NullNotice/NullNotice';
import ActionButton from '../../atoms/ActionButton/ActionButton';
import ConfirmDialog from '../ConfirmDialog/ConfirmDialog';

interface FriendSummaryProps {
    id: number;
    userName: string;
    firstName: string;
    lastName: string;
    lists: Array<{ id: number; name: string; visibility: Visibility; giftCount: number }>;
    onUnfriend?: () => void
}

const FriendSummary = (props: FriendSummaryProps) => {

    const [error, setError] = useState('');

    const [confirmUnfriend, setConfirmUnfriend] = useState(false);

    const handleUnfriendButtonClick = () => {
        setConfirmUnfriend(true);
    }

    const handleConfirmUnfriend = async () => {
        await giftsService
            .removeFriend(props.id)
            .then(data => {
                if (props.onUnfriend) {
                    props.onUnfriend();
                }
            })
            .catch(error => {
                setError(error.response.data.error);
            });
    }

    return (
        <>
            <div className="friend-summary">
                <div className="friend-summary-header">
                    <ExpandedUserFullName userName={props.userName} firstName={props.firstName} lastName={props.lastName} />
                </div>
                <div className="friend-summary-buttons">
                    <ActionButton label='Unfriend' variant='red' onClick={() => handleUnfriendButtonClick()} />
                </div>
                <div className="friend-summary-lists">
                    {props.lists.length > 0 &&
                        <>
                            {props.lists.map(l =>
                                <GiftListSummary key={l.id} id={l.id} name={l.name} visibility={l.visibility} giftCount={l.giftCount} />
                            )}
                        </>
                    }
                </div>
            </div>
            {confirmUnfriend && <ConfirmDialog open={confirmUnfriend} title="Unfriend" message={'Do you really want to unfriend ' + props.userName + '?'} onYesClick={() => handleConfirmUnfriend()} onNoClick={() => setConfirmUnfriend(false)} />}
            {error && <ErrorAlert title='Error' message={error} />}
        </>
    );
}

export default FriendSummary;
import Avatar from "../Avatar/Avatar";
import './ExpandedUserFullName.scss';

interface ExpandedUserFullNameProps {
    userName: string;
    firstName: string;
    lastName: string;
}

const ExpandedUserFullName = (props: ExpandedUserFullNameProps) => {
    return (
        <>
            <div className="expanded-user-icon">
                <Avatar />
            </div>
            <div className="expanded-user-content">
                <span className='expanded-user-name'>{props.userName}</span>
                <span className='expanded-user-full-name'>{props.firstName} {props.lastName}</span>
            </div>
        </>
    );
}

export default ExpandedUserFullName;
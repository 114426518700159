import LinkBox from '../../atoms/LinkBox/LinkBox';
import WideButton from '../../atoms/WideButton/WideButton';
import LoginForm from '../../organisms/LoginForm/LoginForm';

const LoginPage = () => {
    return (
        <>
            <h2>Login to Gifts</h2>
            <LoginForm />
            <LinkBox href="/forgot-password" label="Forgot password?" />
            <WideButton label='Create Account' navigatesTo='/register' />
        </>
    );
}

export default LoginPage;